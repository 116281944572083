import React from 'react';
import * as apis from "../provider/apis";

import UserContext from '../provider/context';

const Auth = (props) => {

    const [userName, setUserName] = React.useState("")
    const [passWord, setPassWord] = React.useState("")

    const user = React.useContext(UserContext);

    const login = (event) => {
        event.preventDefault();

        apis
            .auth(userName, passWord)
            .then((response) => response.json())
            .then((json) => {
                if (json.code === 1) {
                    localStorage.setItem("videouser", JSON.stringify(json.data))
                    user.setUser(json.data)
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    return (
        <div className='flex flex-col  w-screen h-screen justify-center items-center bg-gray-300'>
            <form
                className='flex flex-col justify-center items-center bg-gray-50 w-96 h-56 rounded-md gap-2 border border-gray-100 shadow-lg'
                onSubmit={login}
            >
                <div >
                    <span className="font-medium text-gray-500 text-start">
                        Username:
                    </span>
                    <input type="text" id="username-input"
                        className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 "
                        onChange={(e) => setUserName(e.target.value)}
                    />
                </div>
                <div>
                    <span className="font-medium text-gray-500 text-start ">
                        Passwort:
                    </span>
                    <input type="password" id="password-input"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 "
                        onChange={(e) => setPassWord(e.target.value)}
                    />
                </div>
                <div className='flex justify-center'>
                    <button
                        className=" bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 h-fit rounded"
                        onClick={login}
                    >
                        Login
                    </button>
                </div>
            </form>
        </div>
    )
}

export default Auth;
