import React from "react";
import UserContext from '../provider/context';
import * as apis from "../provider/apis";

const NewDeleteNameSave = (props) => {

    const user = React.useContext(UserContext);

    const [presets, setPresets] = React.useState([])
    const [selected, setSelected] = React.useState(0)
    const [name, setName] = React.useState("")

    const [success, setSuccess] = React.useState(false)

    React.useEffect(() => {
        let timeout
        if (success) {
            timeout = setTimeout(() => setSuccess(false), 1000);
        }
        return () => clearTimeout(timeout);
    }, [success]);

    React.useEffect(() => {
        apis
            .videosettingsget(user, 1)
            .then((response) => response.json())
            .then((json) => {
                const data = json.map((item) => {
                    item.config = JSON.parse(item.config)
                    item.config.id = item.id
                    return item
                })
                setPresets(data)
                setName(data[selected].config.name)
                props.setSettings(data[0].config)
                if (typeof data[0].config.players != "undefined") {
                    props.setPlayers(data[0].config.players)
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }, [])

    const handleSave = () => {
        if (typeof name.length < 1) return;
        props.settings.name = name
        presets[selected].config = props.settings
        presets[selected].config.players = props.players

        apis
            .video_save(user, 1, props.settings)
            .then((response) => response.json())
            .then((json) => {
                if (json.code != 0) {
                    setSuccess(true)
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }


    const handleNew = () => {
        let obj = { id: null, config: { name: "Neuer Eintrag", schablone: [], size: 150, type: "justify-center", background: "background", title: "", linetext: [""], fontSizeLinetext: 25, fontSizeTitletext: 70, showall: true, move: true, players: [] } }
        apis
            .video_save(user, 1, obj.config)
            .then((response) => response.json())
            .then((json) => {
                if (json.code != 0) {
                    obj.id = json.code
                    let copy = [obj, ...presets]
                    setPresets(copy)
                    setSelected(0)
                    setName(obj.config.name)
                    props.setSettings(obj.config)
                    if (typeof obj.config != "undefined") {
                        props.setPlayers(obj.config.players)
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }


    const onChange = (e) => {
        setSelected(e.target.value)
        setName(presets[e.target.value].config.name)
        props.setSettings(presets[e.target.value].config)
        if (typeof presets[e.target.value].config.players != "undefined") {
            props.setPlayers(presets[e.target.value].config.players)
        }
    }

    const handleDelete = () => {
        if (presets.length < 2) return;

        apis
            .videosettingsdelete(user, presets[selected].id)
            .then((response) => response.json())
            .then((json) => {
                if (json.code != 0) {
                    let copy = [...presets]
                    copy.splice(selected, 1)
                    setPresets(copy)
                    props.setSettings(copy[0].config)
                    if (typeof copy[0].config.players != "undefined") {
                        props.setPlayers(copy[0].config.players)
                    }
                    setSelected(0)
                    setSuccess(true)
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    return (
        <div className="flex flex-col gap-2">
            {presets.length > 0 ?
                <div className='flex justify-start items-center'>
                    <span className="font-medium text-gray-400 text-start w-1/2 ">
                        Voreinstellung
                    </span>
                    <div className='flex justify-evenly w-1/2'>
                        <select
                            id="type"
                            className="form-select appearance-none w-full px-2 py-1.5 text-sm font-normal text-gray-700 border border-solid border-gray-300 rounded-md transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            onChange={onChange}
                            value={selected}
                        >
                            {presets.map((option, index) => {
                                return (
                                    <option key={"select" + index} value={index}>{option.config.name}</option>
                                )
                            }
                            )}
                        </select>
                    </div>
                </div> : <div></div>}
            <div className='flex justify-evenly items-center'>
                <button id="save"
                    className={"w-32  hover:bg-blue-700 text-white font-bold py-2 px-4 h-fit rounded bg-blue-500"}
                    onClick={handleNew}
                >
                    {"Neu"}
                </button>
                <button id="save"
                    className={"w-32 hover:bg-blue-700 text-white font-bold py-2 px-4 h-fit rounded bg-blue-500"}
                    onClick={handleDelete}
                >
                    {"Löschen"}
                </button>
            </div>
            <div className='flex justify-evenly items-center'>
                <input type="text" id="name" required
                    className="w-32 peer bg-white border border-gray-300 text-gray-900 text-sm rounded-md  focus:border-blue-500 p-2.5"
                    placeholder={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <button id="save"
                    className={"w-32  hover:bg-blue-700 text-white font-bold py-2 px-4 h-fit rounded bg-blue-500"}
                    onClick={handleSave}
                >
                    Speichern
                </button>
            </div>
            <div className="flex justify-center w-full">
                <div className={success ? " w-64 rounded-md bg-lime-400 text-center py-1 text-white font-bold" : "unvisible"}>
                    {success ? "Gespeichert" : ""}
                </div>
            </div>

        </div>
    )
}

export default NewDeleteNameSave;