import React from "react";

const Toggle = (props) => {
    return (
        <div className='flex justify-start '>
            <span className="font-medium text-gray-400  w-1/2">
                {props.title}
            </span>
            <div className='flex w-1/2 justify-center'>
                <div className="relative inline-block w-10 mr-2 align-middle select-none ">
                    <input type="checkbox" name="toggle_anzeige" id={props.id} className="checked:bg-blue-500 outline-none focus:outline-none right-4 checked:right-0 duration-200 ease-in absolute block w-6 h-6 rounded-full bg-white border-2 appearance-none cursor-pointer"
                        checked={props.checked}
                        onChange={props.onChange}
                    />
                    <label htmlFor={props.id} className="block h-6 overflow-hidden bg-gray-300 rounded-full cursor-pointer">
                    </label>
                </div>
            </div>
        </div>
    )
}

export default Toggle;