import React from 'react';
import { Rnd } from "react-rnd";


const PlayerHead = (props) => {

    const [click, setClick] = React.useState(true)
    const [zIndex, setzIndex] = React.useState(1)

    const handleClick = () => {
        setClick(!click)
    }

    const preventDragHandler = (e) => {
        e.preventDefault();
    }

    const dragStartHandler = (e, d) => {
        setzIndex(9)
    }

    const dragStopHandler = (e, d) => {
        props.onTop(d, e)
        //setzIndex(1)
    }

    React.useEffect(() => {
        setClick(props.showall)
    }, [props.showall])


    return (
        <Rnd
            position={{
                x: props.data.x,
                y: props.data.y,
            }}
            onDragStop={(e, d) => dragStopHandler(e, d)}
            onDragStart={() => dragStartHandler}
            dragGrid={[1, 1]}
            enableResizing={{ top: false, right: false, bottom: false, left: false, topRight: false, bottomRight: false, bottomLeft: false, topLeft: false }}
            disableDragging={!props.move}
            id={props.index}
            style={{ zIndex: zIndex }}
        >

            <div className='bg-transparent ease-in duration-300'>
                <img
                    onDragStart={preventDragHandler}
                    className={click ? "transition duration-500  h-fit w-fit  rounded-full blur-0" : "blur-3xl  h-fit w-fit rounded-full opacity-0 "}
                    src={click ? props.data.src : ""}
                    alt="{user.handle}"
                    onDoubleClick={handleClick}
                    style={{ height: props.size ? props.size : 180, width: props.size ? props.size : 180 }}
                />
            </div>
        </Rnd>
    );
}

export default React.memo(PlayerHead);