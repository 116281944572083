import React from "react";

const TextPlusMinus = (props) => {

    return (
        <div className='flex w-full items-center justify-evenly'>
            <p className=" text-sm py-1 px-1.5 font-lg h-fit text-center font-custom font-bold w-6 rounded bg-white">
                {props.index + 1}
            </p>
            <input type="text" id="default-input"
                className="w-40 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 "
                onChange={(e) => props.onChange(e, props.index)}
                placeholder={props.defaultValue}
            />
            <div className='flex justify-evenly w-16'>
                <button
                    className=" bg-blue-500 hover:bg-blue-700 text-white text-sm font-bold py-1 px-1.5 w-6 font-lg h-fit rounded"
                    onClick={() => props.handlePlus(props.index)}
                >
                    +
                </button>
                <button
                    className=" bg-blue-500 hover:bg-blue-700 text-white text-sm font-bold py-1 px-1.5 w-6 font-lg h-fit rounded"
                    onClick={() => props.handleMinus(props.index)}
                >
                    -
                </button>
            </div>
        </div>
    )
}

export default TextPlusMinus;