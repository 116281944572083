import React from 'react';
import PlayerHead from './PlayerHead';
import Search from './Search';

import ButtonPlusMinus from './ButtonPlusMinus';
import Toggle from './Toggle';
import TextPlusMinus from './TextPlusMinus';
import NewDeleteNameSave from './NewDeleteNameSave';

function VideoSchablone() {

    const [players, setPlayers] = React.useState([]);
    const [settings, setSettings] = React.useState({ name: "Neuer Eintrag", schablone: [], size: 150, type: "justify-center", background: "background", title: "", linetext: [""], fontSizeLinetext: 25, fontSizeTitletext: 70, showall: true, move: true })

    const boxItemRef = React.useRef([]);
    const boxRowRef = React.useRef([]);

    const handleLowerFontSizeLinetext = () => {
        if (settings.fontSizeLinetext !== 2) {
            let copy = { ...settings }
            copy.fontSizeLinetext = copy.fontSizeLinetext - 2
            setSettings(copy)
        }
    }

    const handleHigherFontSizeLinetext = () => {
        let copy = { ...settings }
        copy.fontSizeLinetext = copy.fontSizeLinetext + 2
        setSettings(copy)
    }

    const handleLowerFontSizeTitle = () => {
        if (settings.fontSizeTitletext !== 5) {
            let copy = { ...settings }
            copy.fontSizeTitletext = copy.fontSizeTitletext - 5
            setSettings(copy)
        }
    }

    const handleHigherFontSizeTitle = () => {
        let copy = { ...settings }
        copy.fontSizeTitletext = copy.fontSizeTitletext + 5
        setSettings(copy)
    }

    const handleAddPlayers = (image) => {
        let copy = [...players]
        copy.push({ src: image, x: 1050, y: 0 })
        setPlayers(copy)
    }

    const handleLineText = (e, index) => {
        let copy = { ...settings }
        copy.linetext[index] = e.target.value
        setSettings(copy)
    }

    const handleTitleText = (e) => {
        let copy = { ...settings }
        copy.title = e.target.value
        setSettings(copy)
    }

    const handleShowAll = () => {
        setSettings({ ...settings, showall: !settings.showall })
    }

    const handleMove = () => {
        setSettings({ ...settings, move: !settings.move })
    }

    const handleChangeType = (e) => {
        setSettings({ ...settings, type: e.target.value })
    }

    const handleChangeBackground = (e) => {
        let copy = { ...settings }
        copy.background = e.target.value
        setSettings(copy)
    }

    const getImageSize = () => {
        let maxHeight = (boxHeight - topOffset) / settings.schablone.length
        let maxWidth = innerBoxWidth / Math.max(...settings.schablone)

        return maxWidth > maxHeight ? maxHeight > 275 ? 275 : maxHeight : maxWidth > 275 ? 275 : maxWidth
    }

    const handleAddPlaceholder = (index) => {
        let copy = { ...settings }
        copy.schablone[index] = copy.schablone[index] + 1
        copy.size = getImageSize()
        setSettings(copy)
    }

    const handleRemovePlaceholder = (index) => {
        if (settings.schablone[index] > 0) {
            let copy = { ...settings }
            copy.schablone[index] = copy.schablone[index] - 1
            copy.size = getImageSize()
            setSettings(copy)
        }
    }

    const handleAddLine = () => {
        let copy = { ...settings }
        copy.schablone.push(0)
        copy.size = getImageSize()
        setSettings(copy)
    }

    const handleRemoveLine = () => {
        let copy = { ...settings }
        copy.schablone.pop()
        copy.size = getImageSize()
        setSettings(copy)
    }



    const onTop = (d) => {

        let copy = [...players];
        let move = { ...copy[d.node.id] }.src
        let found = false

        // sticky to amigos
        for (const [index, element] of players.entries()) {
            if (index !== d.node.id) {
                if (d.x > element.x - settings.size / 2 && d.x < element.x + settings.size / 2) {
                    if (d.y > element.y - settings.size / 2 && d.y < element.y + settings.size / 2) {
                        copy[d.node.id].src = element.src
                        copy[index].src = move
                        found = true
                        break
                    }
                }
            }
        }

        // sticky to placeholders
        if (!found) {
            for (const [index, length] of settings.schablone.entries()) {
                for (const [value] of [...Array(length).keys()].entries()) {
                    const schablone = { x: boxRowRef.current[index].offsetLeft + boxItemRef.current[index][value].offsetLeft, y: boxRowRef.current[index].offsetTop + boxItemRef.current[index][value].offsetTop }
                    if (d.x > schablone.x - settings.size / 2 && d.x < schablone.x + settings.size / 2) {
                        if (d.y > schablone.y - settings.size / 2 && d.y < schablone.y + settings.size / 2) {
                            copy[d.node.id] = { src: move, x: schablone.x, y: schablone.y }
                            found = true
                            break
                        }
                    }
                }
            }
        }

        // snap outside of the main box
        if (!found) {
            console.log(d.x, d.y)
            if (d.x > 1025) {
                let rowNum = Math.round((d.x - 1035) / settings.size)
                let colNum = Math.round(d.y / settings.size)
                copy[d.node.id] = {
                    src: move,
                    x: 1035 + settings.size * rowNum + rowNum * 5,
                    y: settings.size * colNum + colNum * 5,
                }
            } else {
                // free movement
                copy[d.node.id] = { src: move, x: d.x, y: d.y }
            }
            //
        }
        setPlayers(copy)
    }

    const boxLeft = 30;
    const boxTop = 33;
    const boxWidth = 1030;
    const boxHeight = 855;
    const topOffset = 230;
    const overLap = 80;
    const innerBoxWidth = (boxWidth / 4) * 3 - overLap * 1.25

    return (
        <div className='flex pt-2 pl-2 gap-2'>
            {/* Background */}
            <div className={"relative"}>
                <img
                    draggable="false"
                    src={"img/" + settings.background + ".jpeg"}
                    alt={"backgroundimage"}
                    width={1600}
                />

                {/* Title */}
                <div className="absolute" style={{ top: boxTop, left: boxLeft, width: boxWidth, height: boxHeight }}>
                    <div className='flex justify-center items-center absolute' style={{ top: 0, left: boxWidth / 4, width: (boxWidth / 4) * 3, height: topOffset }}>
                        <div id="title" className={'text-[#ddf146] p-7 text-center font-custom'} style={{ fontSize: settings.fontSizeTitletext + "px" }}>
                            {settings.title}
                        </div>
                    </div>
                    {/* Lines */}
                    {/* <div className='h-0.5 absolute text-white opacity-90' style={{ backgroundColor: "red", top: 0, left: 1020, height: boxHeight, width: 20 }}></div> */}
                    {
                        settings.schablone.map((value, index) => {
                            let zoneheight = (boxHeight - topOffset) / settings.schablone.length
                            return (
                                <div key={"zone" + index}>
                                    {/* Line */}
                                    <div className='h-0.5 absolute text-white opacity-90' style={{ backgroundColor: "#2a3640", top: topOffset + index * zoneheight, width: boxWidth }}></div>
                                    <div className='absolute flex justify-center items-center' style={{ top: index * zoneheight + 230, left: 40, height: zoneheight, width: 200 }}>
                                        <div className={'text-[#ddf146] p-4 font-custom xl text-center'} style={{ fontSize: settings.fontSizeLinetext + "px" }}>
                                            {settings.linetext[index]}
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    {
                        settings.schablone.map((value, index) => {
                            let zoneheight = (boxHeight - topOffset) / settings.schablone.length
                            boxItemRef.current[index] = []
                            return (
                                <div
                                    key={"schablone" + index}
                                    className={"absolute flex text-white " + settings.type}
                                    ref={(ref) => boxRowRef.current[index] = ref}
                                    style={{
                                        top: topOffset + index * zoneheight + (zoneheight - settings.size) / 2,
                                        left: boxWidth / 4 + overLap / 4,
                                        width: innerBoxWidth
                                    }}
                                >
                                    {[...Array(value).keys()].map((value2, index2) => {
                                        return (
                                            <img
                                                onClick={(event) => event.preventDefault()}
                                                ref={(ref) => boxItemRef.current[index][index2] = ref}
                                                key={"placeholder" + index2}
                                                className={"rounded-full p-0.5"}
                                                style={{ height: settings.size }}
                                                src={"img/empty1.png"}
                                                alt={"placeholder" + index + index2}
                                            />
                                        )
                                    })}
                                </div>
                            )
                        })
                    }
                    {players.map((value, index) => {
                        return (
                            <PlayerHead key={"drag" + index} index={index} data={value} showall={settings.showall} move={settings.move} size={settings.size} onTop={onTop} />
                        )
                    })}
                </div>
            </div>
            <div className='flex flex-col gap-2 '>
                <div className='flex flex-col justify-center gap-2 w-72 bg-gray-100 rounded-lg p-2'>
                    <Search id="Search1" handleAddPlayers={handleAddPlayers} />
                    <Toggle id="Toggle1" checked={settings.showall} onChange={handleShowAll} title="Spieler anzeigen" />
                    <Toggle id="Toggle2" checked={settings.move} onChange={handleMove} title="Spieler bewegen" />
                    <ButtonPlusMinus id="ButtonPlusMinus1" handlePlus={handleAddLine} handleMinus={handleRemoveLine} label="Kategorien" />
                    <ButtonPlusMinus id="ButtonPlusMinus2" handlePlus={handleHigherFontSizeTitle} handleMinus={handleLowerFontSizeTitle} label="Schrift Titel" />
                    <ButtonPlusMinus id="ButtonPlusMinus3" handlePlus={handleHigherFontSizeLinetext} handleMinus={handleLowerFontSizeLinetext} label="Schrift Kategorien" />

                    <div className='flex justify-start items-center'>
                        <span className="font-medium text-gray-400 text-start w-1/2 ">
                            Hintergrund
                        </span>

                        <div className='flex justify-evenly w-1/2'>
                            <select
                                id="background"
                                className="form-select appearance-none w-full px-1 py-1 text-sm font-normal text-gray-700 border border-solid border-gray-300 rounded-md transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                onChange={handleChangeBackground}>
                                <option value="background">Standard</option>
                                <option value="bg1">Fancy</option>
                            </select>
                        </div>
                    </div>

                    <div className='flex justify-start items-center'>
                        <span className="font-medium text-gray-400 text-start w-1/2 ">
                            Schablone
                        </span>
                        <div className='flex justify-evenly w-1/2'>
                            <select
                                id="type"
                                className="form-select appearance-none w-full px-1 py-1 text-sm font-normal text-gray-700 border border-solid border-gray-300 rounded-md transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                onChange={handleChangeType}>
                                <option value="justify-center">Pyramide</option>
                                <option value="justify-start">Tier List</option>
                                <option value="justify-between">Top-List</option>
                            </select>
                        </div>
                    </div>
                    <NewDeleteNameSave settings={settings} setSettings={setSettings} players={players} setPlayers={setPlayers} />
                </div>
                <div className='flex flex-col justify-center gap-2 w-72 bg-gray-100 rounded-lg p-2'>
                    <input type="text" id="default-input"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 "
                        onChange={(e) => handleTitleText(e)}
                        placeholder={settings.title}
                    />
                    {
                        settings.schablone.map((value, index) => {
                            return (
                                <div key={"inputzone" + index} className="flex">
                                    <TextPlusMinus index={index} onChange={handleLineText} defaultValue={settings.linetext[index]} handlePlus={handleAddPlaceholder} handleMinus={handleRemovePlaceholder} />
                                </div>
                            )
                        })
                    }
                </div>

            </div>
        </div >
    )

};



export default VideoSchablone;