import React from 'react';
import * as apis from "../provider/apis";
import UserContext from '../provider/context';


const Search = (props) => {
    const user = React.useContext(UserContext);

    const [searchResults, setSearchResults] = React.useState([{ name: "hallpo" }, { name: "hallpo" }, { name: "hallpo" }, { name: "hallpo" }])

    const ref = React.useRef();

    const requestSearch = (value) => {
        if (value.length > 3) {
            apis
                .search(user, value)
                .then((response) => response.json())
                .then((json) => {
                    setSearchResults(json)
                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            setSearchResults([{ name: "Bitte mindestens 4 Zeichen eingeben" }])
        }
    }


    return (
        <div className='relative bg-yellow z-50' ref={ref}>
            <input type="text" id="search"
                className="search peer/search w-full bg-white border border-gray-300 text-gray-900 text-sm rounded-lg  focus:border-blue-500  p-2.5 "
                placeholder='Suche Spieler oder Team'
                onChange={(e) => requestSearch(e.target.value)}
                onClick={(e) => requestSearch(e.target.value)}
            />
            <div id="dropdown-search" className='invisible ease-in-out delay-150 peer-focus/search:visible pt-1' style={{ top: 20 }}>
                <ul className="absolute bg-white border border-gray-300 w-full rounded-lg py-1 text-sm text-gray-700 max-h-72 overflow-auto" aria-labelledby="dropdown-button-2">
                    {searchResults.map((value, index) => {
                        return (
                            <li key={"searchoptions" + index}>
                                <button
                                    type="button"
                                    className="inline-flex w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 " role="menuitem"
                                    onClick={async () => props.handleAddPlayers(value.img)}>
                                    <div className="inline-flex items-center">
                                        {value.name}
                                    </div>
                                </button>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>
    )
}

export default Search;
