import React from "react";

const ButtonPlusMinus = (props) => {

    return (
        <div className='flex justify-start items-center'>
            <span className="font-medium text-gray-400 text-start w-1/2 ">
                {props.label}
            </span>
            <div className='flex justify-evenly w-1/2'>
                <button
                    className=" bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3 h-fit rounded"
                    onClick={props.handlePlus}
                >
                    +
                </button>
                <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3 h-fit rounded"
                    onClick={props.handleMinus}
                >
                    -
                </button>
            </div>
        </div>
    )
}

export default ButtonPlusMinus;