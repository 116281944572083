let uri
if (window.location.href.match('devbackend.ligainsider.com') || window.location.href.match('localhost')) {
    uri = 'https://samirdev.ligainsider.de/beapi/';
} else {
    uri = 'https://www.ligainsider.de/beapi/'
    //uri = 'https://www.ligainsider.de/beapi/'
}

const attachCreds = (user, body) => {
    return { ...body, user_id: user.user_id, session_token: user.session_token }
}

export function auth(username, password) {
    return fetch(uri + 'auth/', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            type: 'ligainsider',
            body: {
                username: username,
                password: password
            }
        }),
    });
}

export function search(user, searchString) {
    return fetch(uri + 'searchplayericon/', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(attachCreds(user, {
            searchString: searchString,
            type: 'spieler-team'
        })),
    });
}

export function video_save(user, type, settings) {
    return fetch(uri + 'videosettingsset/', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(attachCreds(user, {
            type: type,
            config: JSON.stringify(settings)
        })),
    });
}

export function videosettingsget(user, type) {
    return fetch(uri + 'videosettingsget/', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(attachCreds(user, {
            type: type
        })),
    });
}

export function videosettingsdelete(user, id) {
    return fetch(uri + 'videosettingsdelete/', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(attachCreds(user, {
            id: id
        })),
    });
}