import React from "react";

const minuteLength = 59
const totalMinutes = 7

const Teams = [
    "fc-bayern-muenchen-wappen",
    "borussia-dortmund-wappen",
    "bayer-04-leverkusen-wappen",
    "rb-leipzig-wappen",
    "1fc-union-berlin-wappen",
    "sc-freiburg-wappen",
    "1-fc-koeln-wappen",
    "fsv-mainz-05-wappen",
    "tsg-1899-hoffenheim-wappen",
    "borussia-moenchengladbach-wappen",
    "eintracht-frankfurt-wappen",
    "vfl-wolfsburg-wappen",
    "vfl-bochum-wappen",
    "fc-augsburg-wappen",
    "vfb-stuttgart-wappen",
    "sv-darmstadt-98",
    "1-fc-heidenheim",
    "sv-werder-bremen-wappen"
]

const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9]

const Timer = () => {
    return (
        <div className="flex h-screen flex-col justify-center items-center gap-8 p-4">
            {arr.map((idx, val) => {
                return (
                    <TimerTeam />
                )
            })}
        </div>
    )
}


const TimerTeam = () => {

    const [teamA, setTeamA] = React.useState(Teams[0])
    const [teamB, setTeamB] = React.useState(Teams[1])


    const [seconds, setSeconds] = React.useState(0)
    const [minutes, setMinutes] = React.useState(totalMinutes)

    const [count, setCount] = React.useState("down")

    const [stop, setStop] = React.useState(true)

    React.useEffect(() => {
        const timer = setInterval(() => {

            if (!stop) {
                if (count == "down") {
                    if (minutes >= 0 && seconds > 0) {
                        setSeconds(seconds - 1)
                    }
                    else if (minutes > 0 && seconds == 0) {
                        setSeconds(minuteLength)
                        setMinutes(minutes - 1)
                    }
                    else if (minutes == 0 && seconds == 0) {
                        setCount("up")
                    }
                }

                if (count == "up") {
                    if (seconds < minuteLength) {
                        setSeconds(seconds + 1)
                    }
                    if (seconds == minuteLength) {
                        setSeconds(0)
                        setMinutes(minutes + 1)
                    }
                }
            }


        }, 1000)

        return () => {
            clearInterval(timer);
        };
    });

    return (
        <div className="flex w-full items-center justify-evenly">
            <div className="flex gap-5 items-center justify-center">
                <TeamImage />
                <div>VS</div>
                <TeamImage />
            </div>
            <div className="flex items-center justify-center  gap-5">
                <button onClick={() => setStop(!stop)}
                    className={`btn w-20 ${count == "down" ? "bg-emerald-700" : "bg-red-500"}`}
                >{stop ? "Start" : "Stop"}</button>
                <div className="grid grid-flow-col gap-5 text-center auto-cols-max pl-2">
                    <div className="flex flex-col p-2 bg-neutral rounded-box text-neutral-content">
                        <span className="countdown font-mono text-5xl">
                            <span style={{ "--value": Math.abs(minutes) }}></span>
                        </span>
                        min
                    </div>
                    <div className="flex flex-col p-2 bg-neutral rounded-box text-neutral-content">
                        <span className="countdown font-mono text-5xl">
                            <span style={{ "--value": seconds }}></span>
                        </span>
                        sec
                    </div>
                </div>
            </div>
        </div>
    )
}

const TeamImage = () => {
    const [team, setTeam] = React.useState(Teams[1])

    const [open, setOpen] = React.useState(false)

    const handleSelectTeam = (e) => {
        setTeam(e.target.value)
        setOpen(false)
    }

    return (
        <div className="flex justify-center items-center">
            {!open && <img onClick={() => setOpen(!open)} src={"https://cdn.ligainsider.de/images/teams/medium/" + team + ".png"} alt="Logo" />}
            {open &&
                <select className="w-40 p-2 bg-white rounded-md"
                    onChange={handleSelectTeam} value={team}>
                    <option value="fc-bayern-muenchen-wappen">fc-bayern-muenchen-wappen</option>
                    <option value="borussia-dortmund-wappen">borussia-dortmund-wappen</option>
                    <option value="bayer-04-leverkusen-wappen">bayer-04-leverkusen-wappen</option>
                    <option value="rb-leipzig-wappen">rb-leipzig-wappen</option>
                    <option value="1fc-union-berlin-wappen">1fc-union-berlin-wappen</option>
                    <option value="sc-freiburg-wappen">sc-freiburg-wappen</option>
                    <option value="1-fc-koeln-wappen">1-fc-koeln-wappen</option>
                    <option value="fsv-mainz-05-wappen">fsv-mainz-05-wappen</option>
                    <option value="tsg-1899-hoffenheim-wappen">tsg-1899-hoffenheim-wappen</option>
                    <option value="borussia-moenchengladbach-wappen">borussia-moenchengladbach-wappen</option>
                    <option value="eintracht-frankfurt-wappen">eintracht-frankfurt-wappen</option>
                    <option value="vfl-wolfsburg-wappen">vfl-wolfsburg-wappen</option>
                    <option value="vfl-bochum-wappen">vfl-bochum-wappen</option>
                    <option value="fc-augsburg-wappen">fc-augsburg-wappen</option>
                    <option value="vfb-stuttgart-wappen">vfb-stuttgart-wappen</option>
                    <option value="sv-darmstadt-98-wappen">sv-darmstadt-98-wappen</option>
                    <option value="1fc-heidenheim-wappen">1fc-heidenheim-wappen</option>
                    <option value="sv-werder-bremen-wappen">sv-werder-bremen-wappen</option>
                </select>}
        </div>
    )
}

export default Timer;