
import React from 'react';
import VideoSchablone from './components/VideoSchablone';
import Login from './components/Login';
import UserContext from './provider/context';
import Timer from './components/Timer';

import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {

  const [user, setUser] = React.useState(null);

  React.useEffect(() => {
    const user = JSON.parse(localStorage.getItem("videouser"))
    if (typeof user !== "undefined") {
      setUser(user);
    }
  }, [])


  if (user === null) {
    return (
      <UserContext.Provider value={{ user, setUser }}>
        <Login />
      </UserContext.Provider>
    )
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="timer"
          element={<Timer />}
        />
        <Route path="/" element={<UserContext.Provider value={user}>
          <VideoSchablone className='overflow-auto' />
        </UserContext.Provider>}>
        </Route>
      </Routes>
    </BrowserRouter>
  )
};



export default App;